

<template>
    <div class="main-content">
        <nav id="nav">
       <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
    <img width="30" src="./assets/images/menuicon.png"/>
</button>
<span class="title-pana">Panasonic</span>


</nav>

<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
        <!-- <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5> -->
        <button type="button" class="btn" data-bs-dismiss="offcanvas" aria-label="Close">
                <img width="60" src="./assets/images/menuicon.png"/>
        </button>
        <div id="user">
            <img height="73" src="./assets/images/user_avatar_default.png"/>
            <p>Administrator</p>
        </div>
    </div>
    <div class="offcanvas-body">
        <ul class="menu-list list-group">
        <li :class="{active : currentMenu == 'Home'}" class="  list-group-item d-flex justify-content-between align-items-center">
                             <a href="/">

          <div class="image-menu">
              <img src="./assets/images/home-icon.svg" class="img-fluid" alt="quixote"/>
          </div>
              <span>Homepage</span>
              </a>

        </li>
        <li :class="{active : currentMenu == 'settings'}" class="list-group-item d-flex justify-content-between align-items-center">
                    <a href="/settings">
          <div class="image-menu">
              <img src="./assets/images/network-icon.svg" class="img-fluid" alt="lay"/>
          </div>
          <span>Advance Settings</span>
          </a>
        </li>
        <li :class="{active : currentMenu == 'CueConfig'}" class="list-group-item d-flex justify-content-between align-items-center">  
          <a href="/config">
          <div class="image-menu">
              <img src="./assets/images/config-icon.svg" class="img-fluid" alt="things"/>
          </div>
         <span> Light Cue Configuration </span>
         </a>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">  
            <a @click="logout">
          <div class="image-menu">
              <img src="./assets/images/logout-icon.svg" class="img-fluid" alt="things"/>
          </div>
          <span>Logout</span>
          </a>
        </li>
      </ul>
    </div>
</div>
     <router-view/>
    </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
  data() {
    return {
      currentMenu: 'home'
    };
  },
  mounted() {
        window.setInterval(() => {
          if (this.$route){
            this.currentMenu = this.$route.name;
          }
        }, 1000)
    },
  computed: {
    ...mapState("authfack", ["user"])
  },
  methods: {
     logout: function() {
         store.dispatch("authfack/logout");
        this.$router.go()
      }
  }
};
</script>